import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { removeOrganizationFromAllocation, addOrganizationToAllocation } from "../../Donation/AllocationBuilder/utils";
export var MAX_ALLOCATION = 10;
export var organizationInCart = function(param) {
    var allocation = param.allocation, organization = param.organization;
    return allocation === null || allocation === void 0 ? void 0 : allocation.some(function(alloc) {
        return alloc.organization === organization.slug;
    });
};
export var allSelected = function(param) {
    var allocation = param.allocation, organizations = param.organizations;
    return organizations.every(function(organization) {
        return organizationInCart({
            allocation: allocation,
            organization: organization
        });
    });
};
export var handleSelectOrganization = function(param) {
    var allocation = param.allocation, organization = param.organization, onAllocationChange = param.onAllocationChange;
    var inCart = organizationInCart({
        organization: organization,
        allocation: allocation
    });
    if (inCart) {
        onAllocationChange(removeOrganizationFromAllocation(organization, allocation));
    } else {
        onAllocationChange(addOrganizationToAllocation(organization, allocation));
    }
};
export var handleSelectAllOrganizations = function(param) {
    var allocation = param.allocation, organizations = param.organizations, onAllocationChange = param.onAllocationChange, _remove = param.remove, remove = _remove === void 0 ? false : _remove;
    var newAllocation = _to_consumable_array(allocation);
    var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
    try {
        for(var _iterator = organizations[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
            var organization = _step.value;
            newAllocation = remove ? removeOrganizationFromAllocation(organization, newAllocation) : addOrganizationToAllocation(organization, newAllocation);
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally{
        try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
            }
        } finally{
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }
    onAllocationChange(newAllocation);
};
export var causeAreaNonProfits = [
    {
        title: "Improving human wellbeing",
        description: "",
        causeOverviewLink: "https://www.givingwhatwecan.org/cause-areas/global-health-and-development",
        categorySlug: "health"
    },
    {
        title: "Improving animal welfare",
        description: "",
        causeOverviewLink: "https://www.givingwhatwecan.org/cause-areas/animal-welfare",
        categorySlug: "animals"
    },
    {
        title: "Creating a better future",
        description: "",
        causeOverviewLink: "https://www.givingwhatwecan.org/cause-areas/long-term-future",
        categorySlug: "longtermism"
    },
    {
        title: "Multiple cause areas",
        description: "",
        categorySlug: "infrastructure"
    }, 
];
